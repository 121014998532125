import(/* webpackMode: "eager" */ "/gs-website/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/gs-website/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/gs-website/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["ReactQueryDevtools"] */ "/gs-website/node_modules/@tanstack/react-query-devtools/build/modern/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/gs-website/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/gs-website/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/gs-website/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/gs-website/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/gs-website/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/gs-website/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/gs-website/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/gs-website/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/gs-website/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/gs-website/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/gs-website/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/gs-website/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/gs-website/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/gs-website/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/gs-website/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager" */ "/gs-website/src/app/globals.scss");
;
import(/* webpackMode: "eager" */ "/gs-website/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-ibm-plex-sans\",\"src\":[{\"path\":\"../../public/fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf\",\"weight\":\"400\"},{\"path\":\"../../public/fonts/IBM_Plex_Sans/IBMPlexSans-Medium.ttf\",\"weight\":\"500\"},{\"path\":\"../../public/fonts/IBM_Plex_Sans/IBMPlexSans-SemiBold.ttf\",\"weight\":\"600\"},{\"path\":\"../../public/fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf\",\"weight\":\"700\"}]}],\"variableName\":\"ibmPlex\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/gs-website/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/gs-website/src/components/new/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/gs-website/src/components/new/Navbar/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppContextProvider"] */ "/gs-website/src/contexts/appcontext.tsx");
